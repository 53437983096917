import "./InfoTextStyle.css";

function Bodyweight() {
  const mainContainer = {
    margin: "0 auto",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
  };
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  return (
    <div style={mainContainer}>
      <div style={centerThis}>
        <div>
          <h3
            style={{
              textAlign: "center",
              color: "#4c1679",
              fontSize: "30px",
              marginBottom: "15px",
              marginTop: "30px",
              fontFamily: '"Exo", sans-serif',
            }}
          >
            FITNESS POKER BODYWEIGHT™
          </h3>
          <p
            className="info__text"
            style={{
              color: "black",
              fontSize: "20px",
              textAlign: "justify",
              fontFamily: '"Russo One", sans-serif',
            }}
          >
            Fitness Poker is for those who love exercise or are looking for the
            perfect gift for that friend who practically lives in the gym, yoga
            studio or crossfit box! Challenge yourself and your friends in a
            game of Fitness Poker or use the cards as an inspiration for your
            next workout.
          </p>
        </div>
      </div>
      <div style={{ height: "100px" }}></div>
    </div>
  );
}

export default Bodyweight;
