import "./App.css";
import Banner from "./Banner";
import Bodyweight from "./Bodyweight";
import Gym from "./Gym";
import Yoga from "./Yoga";
import FunctionalFitness from "./FunctionalFitness";
import TabNavigation from "./TabNavigation";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <Banner />
        <Switch>
          <Route exact path="/">
            <Redirect to="/gym" />
          </Route>
          <Route path="/gym">
            <Gym />
          </Route>
          <Route path="/functional">
            <FunctionalFitness />
          </Route>
          <Route path="/bodyweight">
            <Bodyweight />
          </Route>
          <Route path="/yoga">
            <Yoga />
          </Route>
        </Switch>
        <TabNavigation />
      </div>
    </Router>
  );
}

export default App;
