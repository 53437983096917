export const gymImagesData = [
  {
    name: "Blue",
    pictureUrl: "./images/gym_cards/blue.png",
  },
  {
    name: "Green",
    pictureUrl: "./images/gym_cards/green.png",
  },
  {
    name: "Orange",
    pictureUrl: "./images/gym_cards/orange.png",
  },
  {
    name: "Purple",
    pictureUrl: "./images/gym_cards/purple.png",
  },
  {
    name: "Red",
    pictureUrl: "./images/gym_cards/red.png",
  },
  {
    name: "Yoga",
    pictureUrl: "./images/gym_cards/yoga.png",
  },
];
