import useMediaQuery from "./useMediaQuery";
import SwiperCore, { Navigation, Pagination, EffectFlip } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoImages } from "./logoImagesData.js";
import "swiper/swiper-bundle.css";
import "./Swiper.css";

export default function SwiperPage() {
  SwiperCore.use([Navigation, Pagination, EffectFlip]);

  const history = useHistory();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width: 480px)");
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  // const mountCurrentComponent = (index) => {
  //   const route = location.pathname;
  //   if (route === "/gym") {
  //     history.push(logoImages[index].key);
  //   } else if (route === "/functional") {
  //     history.push(logoImages[index].key);
  //   } else if (route === "/bodyweight") {
  //     history.push(logoImages[index].key);
  //   } else if (route === "/yoga") {
  //     history.push(logoImages[index].key);
  //   }
  // };

  // states and observer related to infinite scroll

  const mountComponentByIndex = (index) => {
    history.push(logoImages[index].linkUrl);
  };

  return (
    <section
      className={
        location.pathname === "/gym"
          ? "gym"
          : location.pathname === "/yoga"
          ? "yoga"
          : location.pathname === "/bodyweight"
          ? "bodyweight"
          : location.pathname === "/functional"
          ? "functional"
          : ""
      }
    >
      <Swiper
        effect="flip"
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 1,
          },
          // 1024: {
          //   slidesPerView: 4,
          //   spaceBetween: 0,
          // },
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        spaceBetween={90}
        loop={true}
        onSwiper={(swiper) => mountComponentByIndex(swiper.realIndex)}
        onSlideChange={(swiper) => mountComponentByIndex(swiper.realIndex)}
        // onSlideChange={(swiper) => console.log(swiper)}
        navigation
        style={{
          maxWidth: isMobile ? "400px" : "800px",
          height: isMobile ? "350px" : "400px",
        }}
      >
        {logoImages.map((item, index) => (
          <SwiperSlide key={index} style={centerThis}>
            <Link to={item.linkUrl}>
              <img
                style={{
                  width: isMobile ? "200px" : "250px",
                  cursor: "grab",
                  margin: "auto 20px",
                }}
                src={item.url}
                alt={item.name}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
