import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SleepProvider } from "./Contexts/sleepContext";
import { TimerProvider } from "./Contexts/timerContext";

ReactDOM.render(
  <React.StrictMode>
    <SleepProvider>
      <TimerProvider>
        <App />
      </TimerProvider>
    </SleepProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
