export const yogaCardsData = [
  {
    title: "Big Toe Pose 1",
    type: "Inversion",
    pictureUrl: "./images/yoga_cards/inversions_bigtoepose-1.png",
    description: {
      1: "1. From  staff pose end your right knee and use your hands to bring the right ankle to the left hip crease with the sole of the right foot facing upward. Settle the foot in the hip crease.",
      2: "2. Bend your left knee and use your hands to cross the left ankle over to the right hip crease with the sole of the left foot facing up.",
      3: "3. Sit up tall with a long spine and your shoulders moving away from your ears.",
      4: "4. Relax your knees toward the floor.",
      5: "5. After 10 to 20 breaths, release the legs and repeat the posture with your left foot on the bottom and your right foot on top.",
    },
  },
  {
    title: "Crow Pose",
    type: "Inversion",
    pictureUrl: "./images/yoga_cards/inversions_crow-2.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Step your feet apart, and place your hands on your mat in front of you.",
      3: "3 .Bend your elbows, and lean forward to touch your shins to your upper arms.",
      4: "4. Lift up on the balls of your feet, and continue to lean forward until your feet leave the floor.",
      5: " 5. Hold this pose, then slowly return to your mat in a squatting position.",
    },
  },
  {
    title: "Handstand",
    type: "Inversion",
    pictureUrl: "./images/yoga_cards/inversions_handstand-3.png",
    description: {},
  },
  {
    title: "Headstand",
    type: "Inversion",
    pictureUrl: "./images/yoga_cards/inversions_headstand-4.png",
    description: {},
  },
  {
    title: "Shoulder Pose",
    type: "Inversion",
    pictureUrl: "./images/yoga_cards/inversions_shoulder-5.png",
    description: {
      1: "1. Start by lying down on your back. Bring your legs overhead, rolling your weight into your shoulders.",
      2: "2. Draw your elbows together and place hands on low to mid back for support. Option to tap your feet down on the floor behind your head in plow as you adjust your elbows.",
      3: "3. Firm abs and extend legs upward.",
      4: "4. Make sure the weight remains in the shoulders and the neck is relaxed.",
      5: "5. Gaze up at the toes.",
    },
  },
  {
    title: "Camel Pose",
    type: "Kneeling",
    pictureUrl: "./images/yoga_cards/Kneeling_camel-6.png",
    description: {
      1: " 1. Begin on your knees, with the tops of your feet resting on the floor.",
      2: " 2. Place your hands on your back, with your fingers pointed down.",
      3: "3. Lean back, until your hands touch your feet. If you are having trouble reaching your feet, it’s ok to stay where you are, or to turn your toes under to elevate your heels.",
      4: "4. Hold this pose for a couple of breaths.",
      5: " 5. Bring your hands to your lower belly, and slowly come back to upright.",
    },
  },
  {
    title: "Cat Pose",
    type: "Kneeling",
    pictureUrl: "./images/yoga_cards/Kneeling_cat-7.png",
    description: {
      1: "1. Begin in Table Pose.",
      2: "2. Round your back towards the ceiling and look at your belly.",
      3: "3. Follow with Cow Pose for a gentle yoga flow.",
    },
  },
  {
    title: "Child's Pose",
    type: "Kneeling",
    pictureUrl: "./images/yoga_cards/Kneeling_childs-8.png",
    description: {
      1: "1. Kneel on the floor, touching your big toes together.",
      2: "2. Sit back on your heels, and separate your knees hip-width apart.",
      3: " 3. Slowly bring your head down, and rest it on the floor in front of you.",
      4: " 4. Rest your hands comfortably by your side.",
      5: "5. Relax and breathe. You can stay in this pose as long as you would like.",
    },
  },
  {
    title: "Cow Pose",
    type: "Kneeling",
    pictureUrl: "./images/yoga_cards/Kneeling_cow-9.png",
    description: {
      1: "1. Begin in Table Pose.",
      2: "2. Look up to the ceiling, and allow your belly to sink towards the floor.",
      3: " 3. This pose is paired with Cat Pose for a gentle yoga flow.",
    },
  },
  {
    title: "Bow Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_bowpose-10.png",
    description: {
      1: "1. Begin on your belly, with your hands by your body, palms facing up.",
      2: "2. Bend your knees, with your toes pointing towards the back of your head.",
      3: "3. Reach back with your hands, and hold onto your ankles.",
      4: " 4.Lift your heels towards the ceiling, and your thighs away from the floor. This will lift your upper body off your mat.",
      5: "5. Gaze forward.",
      6: " 6. Hold this position for a couple of breaths, then release your ankles and relax your body onto your mat.",
    },
  },
  {
    title: "Cobra Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_cobra-11.png",
    description: {
      1: "1. Begin by laying on your stomach.",
      2: "2. Place your hands flat on the floor, under your shoulders.",
      3: "3. Straighten your arms, to lift your upper body off the floor.",
      4: " 4. If you are ready for a challenge, bend your knees, and try to touch your toes to the top of your head.",
      5: "5. Hold this position for a couple of breaths, then release your legs and upper body to rest on your mat.",
    },
  },
  {
    title: "Dolphin Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_doldhin-12.png",
    description: {
      1: "1. Come onto your knees and elbows.",
      2: "2. Bring your hands together into a fist and press them into the floor.",
      3: "3. Keep your hands and elbows pressing down into the floor as you curl your toes under.w.",
      4: "4. Lift your knees up and press your hips high up and back.",
      5: "5. Keep pressing your heart toward your thighs.",
      6: "6. Release your knees down and rest.",
    },
  },
  {
    title: "Downward Dog Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_downwarddog-13.png",
    description: {
      1: "1. Begin on your hands and knees, in Table Pose.",
      2: "2. Curl your toes under, straighten your knees, and lift your hips.",
      3: "3. Keep your head between your arms.",
      4: "4. When you are done, lower your knees to your mat.",
    },
  },
  {
    title: "Extended Puppy Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_extendedpuppy-14.png",
    description: {
      1: "1. Begin in Table Pose.",
      2: "2. Walk your hands forward, and lower your head to your mat.",
      3: "3. Breathe deeply, and hold this pose for up to one minute.",
      4: "4. Lower your body into Child’s Pose.",
    },
  },
  {
    title: "Four Limbed Staff Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_fourlimbed-15.png",
    description: {
      1: "1. From Plank Pose, firm shoulder blades against back ribs & press tailbone toward pelvis.",
      2: "2. With an exhale lower body a few inches above floor. Keep legs active & turned slightly inward. Draw pelvis toward navel.",
      3: "3. Don’t let elbows splay out to sides.",
      4: "4. Press bases of index fingers firmly to floor.",
      5: "5. Look forward.",
      6: "6. Release with an exhale.",
    },
  },
  {
    title: "Locust Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_locust-16.png",
    description: {
      1: "1. Laying on your belly, place your arms by your sides palms facing up, finger tips pointing towards your toes and let your forehead rest on the ground.",
      2: "2. Keeping your body strong, begin to lift your head, chest, upper ribs, arms and legs away from the ground. You'll be resting on your lower ribs, belly and top of the pelvis.",
      3: " 3. Think about squeezing your legs in towards each other & imagine reaching your fingers back towards your toes.",
      4: " 4. Remember to breathe! Take 3 deep breaths, then lower everything back down to the ground & have a rest on one ear. Repeat 2-3 times.",
    },
  },
  {
    title: "Plank Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_plank-17.png",
    description: {
      1: "1. Begin in Table Pose.",
      2: "2. Step one leg straight back, and then the other.",
      3: "3. Hold this pose for a couple of breaths.",
      4: "4. Release your body back into Child’s Pose, or continue through your Sun Salutation.",
    },
  },
  {
    title: "Plough Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_plough-18.png",
    description: {
      1: "1. Lie on your back and raise both legs.",
      2: "  2. With your hands below the hips, lift your torso gradually off the floor in a smooth movement and slowly lower both feet to the floor behind your head.",
      3: " 3. Your legs should be straight and your toes should touch the floor.",
      4: " Benefits:Stimulates the thyroid and abdominal organs and stretches the legs and spine.",
    },
  },
  {
    title: "Three Legged Dog Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_threeleggeddog-19.png",
    description: {
      1: "1. Begin in Downward Facing Dog.",
      2: "2. Lift one leg, and hold it for a couple of breaths.",
      3: "3. Slowly lower your leg to your mat, then repeat with your opposite leg.",
      4: "4. To release, lower your knees to your mat.",
    },
  },
  {
    title: "Upward Facing Dog Pose",
    type: "Prone",
    pictureUrl: "./images/yoga_cards/prone_upwardfacingdog-20.png",
    description: {
      1: "1. Begin by laying on your stomach.",
      2: "2. Bring your hands up by your shoulders, and place your palms on the floor.",
      3: " 3. Press into your palms and straighten your arms. Lift your head and torso up as high as you can, and keep your elbows close to your body.",
      4: "4. Hold this pose for a couple of breaths, then slowly lower to the floor.",
    },
  },
  {
    title: "Boat Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_boat-21.png",
    description: {
      1: "1. Sit down on your bottom with your knees bent and feet flat on the floor.",
      2: "2. Lift your heart up tall.",
      3: "3. Keep lifting your heart up as you lift up one leg.",
      4: "4. You can also lift the other leg, or simply switch which leg you lifted.",
      5: "5. Once your legs are lifted, squeeze them so you feel strong.",
      6: " 6. Then, lower your feet back down to the ground into your original position.",
    },
  },
  {
    title: "Cow Face Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_cowface-22.png",
    description: {},
  },
  {
    title: "Forward Bend Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_forwardbend-23.png",
    description: {
      1: "1. Stand up tall and bend forward by rotating your hip joints.",
      2: "2. Keep your knees straight and place your palms on the floor,or hold the back of your ankles.",
      3: "3. Stay in standing forward bend pose for 30 seconds to 1 minute.",
    },
  },
  {
    title: "Garland Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_garland-24.png",
    description: {
      1: "1. Begin by squatting. As you do this, keep your feet close to each other, with your heels on the floor or supported on the ground.",
      2: "2. Spread out your thighs, placing them slightly wider than your torso.",
      3: " 3. Exhale and lean forward such that your torso fits snugly in between your thighs.",
      4: "4. Bring your palms in the Anjali Mudra, and press your elbows against the inner thighs. Doing this will help you extend the front part of your torso.",
      5: " 5. Press the inner thighs against the side of the torso. Then, stretch your arms out, and swing them across such that your shins fit into the armpits. Hold your ankles.",
      6: "6. Hold the pose for a few seconds. Inhale and release.",
    },
  },
  {
    title: "Head to Knee Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_headtoknee-26.png",
    description: {
      1: " 1. Sit on your mat, with your legs straight in front of you.",
      2: " 2. Bend your right knee, gently resting the bottom of your right foot on the inside of your left thigh.",
      3: "3. Lean forward into a comfortable stretch, bringing your upper body to rest on your upper left thigh.",
      4: "  4. Hold onto your left foot, and hold this position for a couple of breaths.",
      5: " 5. Slowly return to upright, then repeat with your opposite side.",
    },
  },
  {
    title: "Lotus Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_lotuspose-27.png",
    description: {
      1: "1. From Staff Pose, bend your right knee and use your hands to bring the right ankle to the left hip crease with the sole of the right foot facing upward. Settle the foot in the hip crease.",
      2: " 2. Bend your left knee and use your hands to cross the left ankle over to the right hip crease with the sole of the left foot facing up.",
      3: "3. Sit up tall with a long spine and your shoulders moving away from your ears.",
      4: " 4. Relax your knees toward the floor.",
      5: "5. After 10 to 20 breaths, release the legs and repeat the posture with your left foot on the bottom and your right foot on top",
    },
  },
  {
    title: "Monkey Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_monkeypose-28.png",
    description: {
      1: " 1. Come to a kneeling position with the thighs perpendicular to the floor. Lower your hands to the floor in front of your knees. Tent your hands so you are up on your fingertips.",
      2: "2. Bring the right leg straight out in front of you with the heel on the floor. Flex your right foot strongly.",
      3: "3. Begin to slide the right foot forward, keeping the right leg straight until you have also extended the left leg as straight as possible behind you. It helps to bring your right heel off your mat so that it slides more easily.",
      4: "4. Keep the hips parallel to one another and facing forward.",
      5: "5. You can keep the toes of the left foot tucked under or release the top of that foot to the floor.",
      6: "6. Hold the pose for 5 to 10 breaths, and then repeat on the other side.",
    },
  },
  {
    title: "Staff Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_staffpose-29.png",
    description: {
      1: " 1. Sit on a mat or blanket and extend legs long in front of you with knees and back straight, using fingertips at your sides for additional support as needed.",
      2: " 2. Turn both ankles in a circle five times, clockwise; repeat going counterclockwise.",
      3: "3. Flex and point your feet 10 times.",
      4: "4. Wiggle your toes; rotate and bend your ankles.",
      5: " 5. Shake out your legs by gently bouncing the knees up and down, which will lubricate your knee and hip joints and help keep them limber.",
    },
  },
  {
    title: "Standing Forwrad Bend Pose",
    type: "Seated",
    pictureUrl: "./images/yoga_cards/seated_standingforwardbend-30.png",
    description: {
      1: "1. From Mountain pose, place the palms on the low back/sacrum with the fingers pointed down. Press into the feet, pull up the knee caps, and squeeze the thighs and buttocks.",
      2: "2. Press the hips forward and begin to arch the torso backwards. Keep the head looking forward, or if it feels safe let it drop all the way back. Use the arms to support your weight and keep the legs and buttocks engaged and strong.",
      3: "3. Breathe and hold 3-7 breaths.",
      4: "4. To release: keep the legs, buttocks and arms strong as you slowly inhale back up, letting the head and neck be the last to come vertical.",
    },
  },
  {
    title: "Eagle Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_eagle-31.png",
    description: {
      1: "1. Start in chair pose.",
      2: "2. Extend your arms at the shoulders. Cross your elbows and place one on top, bringing your wrists and hands closer together above your elbows.",
      3: "3. If you feel steady, lift one leg up and over the other.",
      4: "4. Soar back to standing and repeat on the other side.",
    },
  },
  {
    title: "Extend Big Toe Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_extendedbigtoe-32.png",
    description: {
      1: "1. Bring your left knee toward your belly.",
      2: "2. Reach your left arm inside the thigh, cross it over the front ankle, and hold the outside of your left foot. If your hamstrings are tight, hold a strap looped around the left sole.",
      3: "3. Firm the front thigh muscles of the standing leg, and press the outer thigh inward.",
      4: "4. Inhale and extend the left leg forward. Straighten the knee as much as possible. If you're steady, swing the leg out to the side. Breathe steadily; breathing takes concentration, but it helps you balance.",
      5: "5. Hold for 30 seconds, then swing the leg back to center with an inhale, and lower the foot to the floor with an exhale. Repeat on the other side for the same length of time.",
    },
  },
  {
    title: "Half moon Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_halfmoon-33.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Raise your hands over your head, pressing your palms together.",
      3: "3. Slowly bend your body to one side.",
      4: "4. Keep your hands pressed together, and return your body to standing up straight.",
      5: " 5. Repeat by bending to your opposite side.",
      6: "6. Straighten your body, and lower your arms to your sides, returning to Mountain Pose.",
    },
  },
  {
    title: "Lord of the Dance Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_lordofthedance-34.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Activating your right foot, and on an exhale, bring your left leg back with your left hand. Make sure to draw your navel inward as you move the tailbone down.",
      3: "3. Keep your torso straight and upright. If you need to extend the opposite hand for balance, take care not to strain the lower back as your torso moves forward.",
      4: "4. Pull the shoulders down as you arch into the pose.",
      5: "5. Stabilize the body on the standing leg, and hold the pose for 30 seconds.",
      6: " 6. To release the pose, exhale and bring the leg down slowly. Center yourself in Mountain Pose, then repeat on the other side.",
    },
  },
  {
    title: "Low Lunge Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_lowlunge-35.png",
    description: {
      1: "1. Begin in Downward Facing Dog.",
      2: "2.Step one foot forward, next to your hands. Lower your back knee, and rest the top of your foot on your mat.",
      3: "3. Hold this pose for a couple of breaths, then return to Downward Facing Dog.",
      4: "4. Repeat on your opposite side.",
    },
  },
  {
    title: "Mountain Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_mountain-36.png",
    description: {
      1: "1. Stand tall, with your toes touching and feet slightly apart. If it is more comfortable, you may keep your feet a few inches apart.",
      2: "2. Allow your body to gently sway back and forth.",
      3: " 3. Slowly bring the swaying to a standstill. Stop with your weight balanced evenly on your feet.",
      4: "4. Press your shoulders back, and straighten your arms beside your torso.",
      5: "5. Breathe deeply, and hold this pose for a couple of breaths, or as long as you are comfortable.",
    },
  },
  {
    title: "Prayer Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_prayer-37.png",
    description: {
      1: "1. Start with your feet together and your arms by your side. Press your feet down into the ground, hug the shins towards each other and at the same time broaden the knees ever so slightly until you feel strength in the legs.",
      2: " 2. Tuck your coccyx (tailbone) slightly towards your chest, draw your belly button to spine and lift the chest to the ceiling.",
      3: "3. Draw your shoulders back and down and reach to the ceiling with the crown of your head. The draw the hands to prayer at the center of the chest.",
      4: "4. Hold for a breath and then release.",
    },
  },
  {
    title: "Standing Back Bend Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_standingbackbend-38.png",
    description: {
      1: "1. From Mountain pose, place the palms on the low back/sacrum with the fingers pointed down. Press into the feet, pull up the knee caps, and squeeze the thighs and buttocks.",
      2: "2. Press the hips forward and begin to arch the torso backwards. Keep the head looking forward, or if it feels safe let it drop all the way back. Use the arms to support your weight and keep the legs and buttocks engaged and strong.",
      3: "3. Breathe and hold 3-7 breaths.",
      4: "4. To release: keep the legs, buttocks and arms strong as you slowly inhale back up, letting the head and neck be the last to come vertical.",
    },
  },
  {
    title: "Tree Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_tree-39.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: " 2. To help with balance, extend your arms out to either side.",
      3: "3. Bend one knee to the side, and set your toes on the floor and your heel against your ankle.",
      4: "4. Lift your hands up to the sky.",
      5: " 5. Hold this pose for a couple of breaths, then lower your arms and stand on both legs.",
      6: "6. Repeat on your opposite side.",
    },
  },
  {
    title: "Triangle Pose",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_triangle-40.png",
    description: {
      1: "1. Begin in Star Pose.",
      2: "2.Turn one foot, so it is pointing to the side.",
      3: "3. Keep your arms straight. Bend through your hips, over your foot with your toes pointing out. Rest your lower hand on your shin or ankle and stretch your upper arm up to the sky.",
      4: "4. Hold this pose for a couple of breaths, then return to standing. Lower your hands to your sides.",
      5: "5. Repeat on your opposite side.",
    },
  },
  {
    title: "Warrior One",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_warrior1-41.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Take a big step forward with one foot, leaving your other foot in place.",
      3: " 3. Raise your hands high over your head, palms facing each other.",
      4: "4. Turn your back foot, so your toes are pointed away from your body, and bend your front knee.",
      5: " 5. Hold this pose for a couple of breaths, then bring your legs together and return to Mountain Pose.",
      6: "6. Repeat on your opposite side.",
    },
  },
  {
    title: "Warrior Two",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_warrior2-42.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Step your feet wide apart and stretch your arms out to either side, palms facing down.",
      3: "3. Turn one foot, so it is pointing to the side, then bend your knee on that leg.",
      4: "4. Look beyond your fingertips for a couple of breaths.",
      5: "5. Straighten your front leg, then turn your toes back to facing forward.",
      6: "6. Repeat on your opposite side.",
    },
  },
  {
    title: "Warrior Three",
    type: "Standing",
    pictureUrl: "./images/yoga_cards/standing_warrior3-43.png",
    description: {
      1: "1. Begin in Mountain Pose.",
      2: "2. Bring palms together at the center of your chest.",
      3: "3. Extend one leg back, balancing on the big toe.",
      4: "4. Hinge from the hips, bringing the upper body and leg parallel to the floor.",
      5: "5. Extend back through the foot and forward through the top of the head.",
      6: "6. Press down through the standing foot, lift the knee and firm the thigh.",
      7: "7. Level hips and shoulders parallel to the floor.",
      8: "8. Keep palms together or extend arms along ears to challenge core strength.",
    },
  },
  {
    title: "Bridge Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_bridge-44.png",
    description: {
      1: " 1. Lay down on your back. Rest your arms on the floor, alongside your body.",
      2: "2. Bend your knees, and set your feet flat on the floor.",
      3: "3. Lift your hips towards the ceiling.",
      4: "4. Hold this pose for a few breaths.",
      5: "5. Slowly lower your hips back to the floor.",
    },
  },
  {
    title: "Bridge Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_bridge-44.png",
    description: {
      1: "1. Lay down on your back. Rest your arms on the floor, alongside your body.",
      2: "2. Bend your knees, and set your feet flat on the floor.",
      3: "3. Lift your hips towards the ceiling.",
      4: "4. Hold this pose for a few breaths.",
      5: "5. Slowly lower your hips back to the floor.",
    },
  },
  {
    title: "Corpse",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_corpse-45.png",
    description: {
      1: "1.Lay on your back, with your arms comfortably close to your body and your palms facing up.",
      2: "2.Close your eyes, and focus on deep breathing.",
      3: "3.For the full benefit, stay in this pose as long as you are comfortable.",
    },
  },
  {
    title: "Legs Up The Wall Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_legswall-46.png",
    description: {
      1: "1. Begin by laying on your back.",
      2: "2. Lift your legs up towards the ceiling. If you are against a wall, gently rest your legs against the wall.",
      3: "3. Rest your arms on the floor next to your body.",
      4: "4. Hold this pose for as long as you are comfortable.",
      5: "5. When you are ready, roll to your side and rest there for a couple of minutes.",
      6: "6. Gently roll yourself up to sitting.",
    },
  },
  {
    title: "Side Plank Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_sideplank-47.png",
    description: {
      1: "1. Begin in Plank Pose.",
      2: "2. Shift your weight to one side of your body, and stretch your hand up towards the ceiling.",
      3: "3. Keep your head aligned with your spine.",
      4: "4. Hold this pose for as long as you can, then return to Plank Pose.",
      5: "5. Repeat on your opposite side.",
    },
  },
  {
    title: "Upward Plank Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_upwardplank-48.png",
    description: {
      1: "1. Begin in Reverse Table.",
      2: "2. Straighten your legs out, one at a time.",
      3: "3. Lift your hips up to the ceiling, and allow your head to relax back.",
      4: "4. Hold this pose for a couple of breaths, then release your body to sitting.",
    },
  },
  {
    title: "Wheel Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_wheel-49.png",
    description: {
      1: "1. Begin by lying on your back, with your knees bent, and your feet flat on the floor as close to your body as possible.",
      2: "2. Reach up over your head, and place your palms flat on your mat by your head with your fingers pointing towards your shoulders.",
      3: "3. Press your hands and feet into the floor as you lift your belly towards the ceiling.",
      4: " 4. Hold this pose for a couple of breaths, then gently lower your body to your mat.",
    },
  },
  {
    title: "Wind Relieving Pose",
    type: "Supine",
    pictureUrl: "./images/yoga_cards/supine_wind_rel-50.png",
    description: {
      1: "1. Begin by laying on your back.",
      2: "2. Bend your knees, and bring both of your legs up to your chest. Hold your legs a little below your knees.",
      3: "3. Gently hug your legs a little closer to your chest.",
      4: " 4. Take a few relaxing breaths, then release your legs to your mat.",
    },
  },
];

// {
//   title: "",
//   type: "",
//   pictureUrl: "./images/yoga_cards/",
//   description: {
//   1: "",
//       2: "",
//       3: "",
//       4: "",
//       5: "",
//       6: "",
// },
// },
