export const logoImages = [
  {
    name: "gym",
    key: 0,
    linkUrl: "/gym",
    url:
      "https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/logos/gym_logo.png",
  },
  {
    name: "ff",
    key: 1,
    linkUrl: "/functional",
    url:
      "https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/logos/functional_logo.png",
  },
  {
    name: "bodyweight",
    key: 2,
    linkUrl: "/bodyweight",
    url:
      "https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/logos/body_logo.png",
  },
  {
    name: "yoga",
    key: 3,
    linkUrl: "/yoga",
    url:
      "https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/logos/yoga_logo.png",
  },
];
