import { createContext, useState, useRef } from "react";
import countinSound from "../files/countdown.mp3";
import yogaSound from "../files/yoga_timer_mark.mp3";

export const TimerContext = createContext();

export const TimerProvider = (props) => {
  const [countdown, setCountdown] = useState(null);
  const countdownSound = useRef(new Audio(countinSound));
  const yogaTimerMark = useRef(new Audio(yogaSound));
  const running = useRef(false);
  const interval = useRef();

  const stop = () => {
    setCountdown(null);
    running.current = false;
    clearInterval(interval.current);
    countdownSound.current.pause();
    countdownSound.current.currentTime = 0;
    yogaTimerMark.current.pause();
    yogaTimerMark.current.currentTime = 0;
  };

  const startTimer = (seconds) => {
    running.current = true;
    setTimeout(async () => {
      while (running.current) {
        countdownSound.current.play();
        await timerHandler(10);
        await timerHandler(seconds);
      }
    }, 0);
  };

  const startYogaTimer = (seconds) => {
    running.current = true;
    setTimeout(async () => {
      while (running.current) {
        yogaTimerMark.current.play();
        await timerHandler(seconds, true);
      }
    }, 0);
  };

  const timerHandler = (seconds, increment) => {
    let loopCount = 0;
    setCountdown(increment ? 1 : seconds);
    debugger;
    return new Promise(function (resolve, _reject) {
      interval.current = setInterval(() => {
        setCountdown((prevState) =>
          increment ? prevState + 1 : prevState - 1
        );
        loopCount++;
        if (loopCount === seconds) {
          clearInterval(interval.current);
          resolve(true);
        }
      }, 1000);
    });
  };

  return (
    <TimerContext.Provider
      value={{ countdown, startTimer, stop, startYogaTimer }}
    >
      {props.children}
    </TimerContext.Provider>
  );
};
