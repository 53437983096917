import React from "react";
import "./Banner.css";
import Swiper from "./Swiper";
import { useLocation } from "react-router-dom";

function Banner() {
  const location = useLocation();
  return (
    <>
      <div
        className="banner"
        style={{
          backgroundSize: "cover",
          backgroundImage:
            location.pathname === "/yoga"
              ? "url(https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/backgrounds/yoga_bg.png)"
              : location.pathname === "/gym"
              ? "url(https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/backgrounds/gym_bg.png)"
              : location.pathname === "/functional"
              ? "url(https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/backgrounds/gym_bg.png)"
              : location.pathname === "/bodyweight"
              ? "url(https://raw.githubusercontent.com/alexek1987/fitnesspoker_assets/master/backgrounds/bodyweight_bg.png)"
              : "",
          backgroundPosition: "top center",
          backgroundColor:
            location.pathname === "/yoga"
              ? "#F18FA6"
              : location.pathname === "/bodyweight"
              ? "white"
              : location.pathname === "/functional"
              ? "#1f1f26"
              : location.pathname === "/gym"
              ? "#1f1f26"
              : "",
        }}
      >
        <div className="banner__content">
          <div
            style={{
              marginTop: "-150px",
            }}
          >
            <Swiper />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
