import rules from "./images/rules.png";
import "./FunctionalFitness.css";
import "./InfoTextStyle.css";

function FunctionalFitness() {
  const mainContainer = {
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: "#1f1f26",
  };
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  return (
    <div style={mainContainer}>
      <div style={centerThis}>
        <div>
          <h3
            style={{
              textAlign: "center",
              color: "#f0c902",
              fontSize: "30px",
              marginBottom: "15px",
              marginTop: "30px",
              fontFamily: '"Exo", sans-serif',
            }}
          >
            FITNESS POKER
            <br />
            FUNCTIONAL FITNESS™
          </h3>
          <p
            className="info__text"
            style={{
              color: "white",
              fontSize: "20px",
              textAlign: "justify",
              fontFamily: '"Russo One", sans-serif',
            }}
          >
            Fitness Poker is for those who love exercise or are looking for the
            perfect gift for that friend who practically lives in the gym, yoga
            studio or crossfit box! Challenge yourself and your friends in a
            game of Fitness Poker or use the cards as an inspiration for your
            next workout.
          </p>
        </div>
      </div>

      <div style={centerThis}>
        <img style={{ width: "300px", marginTop: "30px" }} src={rules} alt="" />
        <div className="rules__text">
          <h3 style={{ marginTop: "50px" }}>RULES</h3>
          <p>
            Shuffle the deck and deal 5 cards to each participant. The cards are
            personal and are not shown to other players. If you don’t have all
            the equipment shown on the cards, you can remove these cards before
            starting. The remaining exercises can be done without any or little
            equipment.
          </p>
          <br />
          <h3>GAME START</h3>
          <p>
            Choose which exercise you want to give to the other player/players
            and place it visibly on the floor or table. Each round you must play
            a minimum of one card. If you have more cards in the same color /
            suit you can stack them. When the last card has been laid down, you
            add up the exercises and start the workout. Make it more competitive
            by adding a timer at 30 or 60 seconds. When you have finished the
            exercises, take new cards until you have 5 on hand and wait for the
            other players.
          </p>
          <br />
          <h3>SPECIAL CARDS</h3>
          <h3>Reverse card</h3>
          <p>
            The reverse card can be played at any time during the game. When
            played the players must then play their own cards and you can skip
            the round and rest. If more than two players are in the game, the
            others must also do the exercises. Double Trouble This card doubles
            the value of your cards when played. This must be played when
            placing your cards.
          </p>
        </div>
      </div>
      <div style={{ height: "100px" }}></div>
    </div>
  );
}

export default FunctionalFitness;
