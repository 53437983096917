import { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { TimerContext } from "./Contexts/timerContext";
import "./GameControls.css";
import { SleepContext } from "./Contexts/sleepContext";

function GameControls() {
  const { startYogaTimer, startTimer, countdown, stop } = useContext(
    TimerContext
  );
  const [isPlay, setIsPlay] = useState(false);

  const { toggleSleep } = useContext(SleepContext);

  const location = useLocation();

  useEffect(() => {
    return () => {
      stop();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isPlay ? (
        <>
          {!countdown && (
            <div
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                marginBottom: "30px",
                marginTop: "30px",
                display: "flex",
              }}
            >
              <Button
                onClick={() => {
                  if (location.pathname === "/yoga") {
                    startYogaTimer(30);
                  } else {
                    startTimer(60);
                  }
                }}
                className="timer__buttons"
                style={{
                  backgroundColor:
                    location.pathname === "/yoga" ? "#DE7BE6" : "white",
                  color: location.pathname === "/yoga" ? "white" : "#408B25",
                  fontSize: "20px",
                  fontWeight: "900",
                  marginRight: "10px",
                }}
                color="secondary"
                variant="contained"
              >
                {location.pathname === "/yoga" ? "30 SEC" : "EASY"}
              </Button>
              <Button
                onClick={() => {
                  if (location.pathname === "/yoga") {
                    startYogaTimer(60);
                  } else {
                    startTimer(45);
                  }
                }}
                className="timer__buttons"
                style={{
                  color: location.pathname === "/yoga" ? "white" : "#EDC845",
                  fontWeight: "900",
                  backgroundColor:
                    location.pathname === "/yoga" ? "#DE7BE6" : "white",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
                color="secondary"
                variant="contained"
              >
                {location.pathname === "/yoga" ? "60 SEC" : "NORMAL"}
              </Button>
              <Button
                onClick={() => {
                  if (location.pathname === "/yoga") {
                    startYogaTimer(90);
                  } else {
                    startTimer(35);
                  }
                }}
                className="timer__buttons"
                style={{
                  backgroundColor:
                    location.pathname === "/yoga" ? "#DE7BE6" : "#ED3833",
                  fontSize: "20px",
                  fontWeight: "900",
                }}
                color="secondary"
                variant="contained"
              >
                {location.pathname === "/yoga" ? "90 SEC" : "HARD"}
              </Button>
            </div>
          )}
          <p
            style={{
              fontFamily: '"Russo One", sans-serif',
              marginBottom: "10px",
              fontSize: "140px",
              color:
                location.pathname === "/bodyweight"
                  ? "black"
                  : location.pathname === "/yoga"
                  ? "black"
                  : "white",
            }}
          >
            {countdown}
          </p>

          {countdown && (
            <Button
              onClick={() => {
                stop();
                toggleSleep();
              }}
              className="timer__buttons"
              style={{
                fontSize: "20px",
                fontWeight: "900",
                marginBottom: "20px",
              }}
              color="secondary"
              variant="contained"
            >
              STOP
            </Button>
          )}
        </>
      ) : (
        <>
          <div
            className="body__buttons"
            style={{ marginTop: "40px", display: "flex" }}
          >
            <Button
              className="play__button"
              variant="contained"
              color="primary"
              style={{
                backgroundColor:
                  location.pathname === "/bodyweight"
                    ? "#472061"
                    : location.pathname === "/yoga"
                    ? "#DE7BE6"
                    : "white",
                color:
                  location.pathname === "/bodyweight"
                    ? "#ffff !important"
                    : location.pathname === "/gym"
                    ? "#ed2727"
                    : location.pathname === "/yoga"
                    ? "white"
                    : "#edc700",
              }}
              onClick={() => {
                setIsPlay(true);
                toggleSleep();
              }}
            >
              PLAY
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default GameControls;
