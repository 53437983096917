import { useRef } from "react";
import "./YogaCardItem.css";

function YogaCardItem({ card, index }) {
  const yogaImageRef = useRef();
  return (
    <div
      style={{ position: "relative" }}
      className="cards__item__wrap"
      onMouseEnter={() => {
        yogaImageRef.current.style.display = "block";
      }}
      onMouseLeave={() => {
        yogaImageRef.current.style.display = "none";
      }}
    >
      <div
        ref={yogaImageRef}
        style={{
          display: "none",
          borderRadius: "10px",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.4)",
          color: "white",
          position: "absolute",
          zIndex: "999",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h3 style={{ textAlign: "center", marginTop: "25px" }}>{card.title}</h3>

        {Object.keys(card.description).map((key) => (
          <p
            style={{
              textAlign: "justify",
              marginTop: "25px",
              marginLeft: "12px",
              marginRight: "12px",
            }}
          >
            {card.description[key]}
          </p>
        ))}
      </div>
      <img className="cards__item__img" alt="" src={card.pictureUrl} />
    </div>
  );
}

export default YogaCardItem;
