import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import TimerIcon from "@material-ui/icons/Timer";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AppBar from "@material-ui/core/AppBar";
import Modal from "./Modal";
import Video from "./Video";
import GameControls from "./GameControls";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

const initNavState = {
  games: false,
  video: false,
  timers: false,
};

export default function SimpleBottomNavigation() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [navControl, setNavControl] = React.useState(initNavState);

  const location = useLocation();

  // console.log(value);
  // console.log(navControl);

  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  const navControlHandler = (name) => {
    if (name) {
      setNavControl({ ...initNavState, [name]: true });
    } else {
      setNavControl(initNavState);
      setValue(0);
    }
  };

  const redirectToBellvivo = () => {
    if (location.pathname === "/yoga") {
      window.open("https://bellvivo.com/lek-spel/fitness-poker-yoga", "_blank");
    } else if (location.pathname === "/gym") {
      window.open("https://bellvivo.com/lek-spel/fitness-poker-gym", "_blank");
    } else if (location.pathname === "/functional") {
      window.open(
        "https://bellvivo.com/lek-spel/fitness-poker-functional-fitness",
        "_blank"
      );
    } else if (location.pathname === "/bodyweight") {
      window.open(
        "https://bellvivo.com/lek-spel/fitness-poker-body-weight",
        "_blank"
      );
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        style={{ top: "auto", bottom: 0 }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
          style={{ width: "auto", height: "auto" }}
        >
          <BottomNavigationAction
            onClick={() => navControlHandler("games")}
            label="Games"
            icon={<SportsEsportsIcon />}
          />
          <BottomNavigationAction
            onClick={() => navControlHandler("timers")}
            label="Timers"
            icon={<TimerIcon />}
          />
          {location.pathname !== "/yoga" && (
            <BottomNavigationAction
              label="How to"
              onClick={() => navControlHandler("video")}
              icon={<SlowMotionVideoIcon />}
            />
          )}

          <BottomNavigationAction
            onClick={redirectToBellvivo}
            label="Buy"
            icon={<ShoppingCartIcon style={{ color: "green" }} />}
          />
        </BottomNavigation>
      </AppBar>
      {navControl.video && (
        <Modal width="500px" closeModal={() => navControlHandler(false)}>
          <Video />
        </Modal>
      )}
      {navControl.timers && (
        <Modal
          height="300px"
          width="500px"
          closeModal={() => navControlHandler(false)}
        >
          {/* color white to , color out 0 that display in ui on Yoga component/timer,(not a bug a feature)  */}
          <div style={centerThis}>
            <h3
              style={{
                textAlign: "center",
                color:
                  location.pathname === "/bodyweight"
                    ? "black"
                    : location.pathname === "/yoga"
                    ? "black"
                    : "white",
                fontSize: "30px",
                marginBottom: "15px",
                marginTop: "30px",
                fontFamily: '"Exo", sans-serif',
              }}
            >
              TIMERS
            </h3>
            <GameControls />
          </div>
        </Modal>
      )}
    </>
  );
}
