import { createContext, useState } from "react";
import NoSleep from "nosleep.js";

export const SleepContext = createContext();

export const SleepProvider = (props) => {
  const noSleep = new NoSleep();
  const [isSleep, setIsSleep] = useState(false);
  console.log(isSleep);

  function toggleSleep() {
    if (!isSleep) {
      noSleep.enable();
      setIsSleep(true);
    } else {
      noSleep.disable();
      setIsSleep(false);
    }
  }

  return (
    <SleepContext.Provider value={{ setIsSleep, isSleep, toggleSleep }}>
      {props.children}
    </SleepContext.Provider>
  );
};
