import useMediaQuery from "./useMediaQuery";
import { gymImagesData } from "./gymImagesData.js";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "./GymSwiper.css";

export default function GymSwiper() {
  SwiperCore.use([Navigation, Pagination]);
  const isMobile = useMediaQuery("(max-width: 480px)");
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };
  return (
    <Swiper
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 2,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 3,
        },
        // 1024: {
        //   slidesPerView: 4,
        //   spaceBetween: 0,
        // },
      }}
      loop={true}
      pagination={{ clickable: true }}
      spaceBetween={-60}
      navigation
      style={{
        maxWidth: isMobile ? "400px" : "800px",
        height: isMobile ? "350px" : "400px",
      }}
    >
      {gymImagesData.map((item, index) => (
        <SwiperSlide key={index} style={centerThis}>
          <img
            style={{
              width: isMobile ? "200px" : "250px",
              cursor: "grab",
              margin: "auto 20px",
            }}
            src={item.pictureUrl}
            alt={item.name}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
