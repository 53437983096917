import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

export default function ControlledOpenSelect({ type, setType }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={((e) => setType(e.target.value), handleChange)}
            value={type}
          >
            <MenuItem value={"All"}>All Poses</MenuItem>
            <MenuItem value={"Inversion"}>Inversions</MenuItem>
            <MenuItem value={"Kneeling"}>Kneeling Poses</MenuItem>
            <MenuItem value={"Prone"}>Prone Poses</MenuItem>
            <MenuItem value={"Seated"}>Seated Poses</MenuItem>
            <MenuItem value={"Standing"}>Standing Poses</MenuItem>
            <MenuItem value={"Supine"}>Supine Poses</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}
