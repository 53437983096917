import { useState, useRef, useCallback, useEffect } from "react";
import "./Yoga.css";
import CardSelector from "./CardSelector";
import { yogaCardsData } from "./yogaCardData.js";
import YogaCardItem from "./YogaCardItem";

function Yoga() {
  const [filterCards, setFilterCards] = useState("All");
  const [loadMore, setLoadMore] = useState(false);
  const [indexRange, setIndexRange] = useState({ endIndex: 5 });
  const observer = useRef();
  const [yogaCards, setYogaCards] = useState(yogaCardsData);

  const lastDataElementRef = useCallback((node) => {
    // if there's an observer in the reference then disconnect it
    if (observer.current) observer.current.disconnect();
    // assigning an obserer to the refernce
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // set load to display loading
        setLoadMore(true);
        // setting timeout to fake a loading ui (because the data is already fetched, and no refetch needed)
        setTimeout(() => {
          // render out 20 more cards profiles to ui
          setIndexRange((prevState) => ({
            endIndex: prevState.endIndex + 6,
          }));
          // disable the loading ui
          setLoadMore(false);
        }, 2000);
      }
    });
    // watch the last element on current page in the dom
    if (node) observer.current.observe(node);
  }, []);

  const mainContainer = {
    maxWidth: "100%",
    backgroundColor: "#de7be6",
    margin: "0 auto",
  };
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  useEffect(() => {
    if (filterCards) {
      let sortedCards = [...yogaCards];
      sortedCards = sortedCards.sort((a, b) => {
        if (a.type === filterCards) {
          return -1;
        } else {
          return 1;
        }
      });
      setYogaCards(sortedCards);
    }
    //eslint-disable-next-line
  }, [filterCards]);

  return (
    <div style={mainContainer}>
      <div style={centerThis}>
        <h3
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "30px",
            marginBottom: "15px",
            marginTop: "30px",
            fontFamily: '"Exo", sans-serif',
          }}
        >
          FITNESS POKER YOGA™
        </h3>
        <p
          className="info__text"
          style={{
            color: "white",
            fontSize: "20px",
            textAlign: "justify",
            fontFamily: '"Courgette", cursive',
          }}
        >
          50 different yoga poses for inspiration and help before the next yoga
          session at home. Practical with names of exercises in both Sanskrit
          and Asana. Find ready-made routines in the drop down below.
        </p>
        <h3
          style={{
            textAlign: "center",
            color: "white",
            fontSize: "30px",
            marginBottom: "15px",
            marginTop: "30px",
            fontFamily: '"Exo", sans-serif',
          }}
        >
          YOGA CARDS
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <CardSelector type={filterCards} setType={setFilterCards} />
            <p
              style={{
                fontFamily: '"Courgette", cursive',
                color: "white",
                marginLeft: "20px",
              }}
            >
              Tap each card for detailed instructions
            </p>
          </div>
        </div>

        <div className="yoga__cards_container">
          {yogaCards.map((card, index) => {
            if (index <= indexRange.endIndex) {
              return filterCards === card.type ? (
                <div
                  ref={
                    index === indexRange.endIndex ? lastDataElementRef : null
                  }
                  key={index}
                  className="yoga__card"
                >
                  <YogaCardItem card={card} />
                </div>
              ) : filterCards === "All" ? (
                <div
                  ref={
                    index === indexRange.endIndex ? lastDataElementRef : null
                  }
                  key={index}
                  className="yoga__card"
                >
                  <YogaCardItem card={card} />
                </div>
              ) : null;
            } else {
              return null;
            }
          })}
        </div>
        {loadMore && <div style={{ fontWeight: "700", color: "white" }}>Loading....</div>}
      </div>
      <div style={{ height: "70px" }}></div>
    </div>
  );
}

export default Yoga;
