import React from "react";
import "video-react/dist/video-react.css";
import fitnessVideo from "./files/fitnesspoker.mp4";
import { useLocation } from "react-router-dom";

function Video() {
  const location = useLocation();

  const videoContainerStyle = {
    display: "grid",
    placeItems: "center",
    margin: "0 auto",
  };

  return (
    <div className="video">
      <div style={videoContainerStyle} className="video__container">
        <h3
          id="video"
          style={{
            color: location.pathname === "/bodyweight" ? "black" : "white",
            fontSize: "30px",
            marginBottom: "10px",
            marginTop: "30px",
            fontFamily: '"Exo", sans-serif',
          }}
        >
          VIDEO
        </h3>
        <video
          controls
          autoPlay
          loop
          playsInline
          src={fitnessVideo}
          style={{ maxWidth: "80%", maxHeight: "60vh" }}
        />
        <div style={{ height: "100px" }}></div>
      </div>
    </div>
  );
}

export default Video;
