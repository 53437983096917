import GymSwiper from "./GymSwiper";
import "./InfoTextStyle.css";

function Gym() {
  const mainContainer = {
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: "#1f1f26",
  };
  const centerThis = {
    display: "grid",
    placeItems: "center",
  };

  return (
    <div style={mainContainer}>
      <div style={centerThis}>
        <div>
          <h3
            style={{
              textAlign: "center",
              color: "#ed2727",
              fontSize: "30px",
              marginBottom: "15px",
              marginTop: "30px",
              fontFamily: '"Exo", sans-serif',
            }}
          >
            FITNESS POKER GYM™
          </h3>
          <p
            style={{
              color: "white",
              fontSize: "20px",
              textAlign: "justify",
              fontFamily: '"Russo One", sans-serif',
            }}
            className="info__text"
          >
            Fitness Poker is for those who love exercise or are looking for the
            perfect gift for that friend who practically lives in the gym, yoga
            studio crossfit box! Challenge yourself and your friends in a game
            of Fitness Poker or use the cards as an inspiration for your next
            workout.
          </p>
        </div>
      </div>

      <GymSwiper />
      <div style={{ height: "100px" }}></div>
    </div>
  );
}

export default Gym;
